import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Observable } from 'rxjs';
import { UsuariosAdm, AdminClientes } from '../../../modules/interfaces/interface';
import { environment } from '../../../../environments/environment';
import {
  cargarPedido,
  login,
  NuevosPedidos,
  RepAsignado,
  RetirarPedido,
  Usuarios,
} from 'src/app/customer';



@Injectable({
  providedIn: 'root',
})
export class PruebasService {
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: 'Auth dHRwOjIwMjAu',
  });
  private baseURL : string = environment.baseURL
  private baseURLLocker: string = environment.baseUserURL
  private API_TEST =  this.configService.getConfig();
  private url_TEST = this.API_TEST['DatosMethanex'];
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) { }

  async Prueba(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['Diego']}`;
    //console.log(url, '-----------PRUEBA-----------');
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return { status: false, code: 804, message: error };
    }
  }

  async TraerPedido(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'PED',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async TraerAgignados(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Reporte/RepAsignado`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async cargarPedido(request: cargarPedido): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Flujo/CargarPedido`;
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  // async cargarPedidoCasillerosAbrir(): Promise<any> {
  //   const API_URL = await this.configService.getConfig();
  //   const url = `${API_URL['ApiPuertas']}PruebaInstall/CasillerosAbrir`;
  //   try {
  //     return await this.httpClient.get(url, { headers: this.headers }).toPromise();
  //   } catch (error) {
  //     return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
  //   }
  // }

  async traerZona(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'ZON',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarZona(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionZonas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarRegion(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionRegiones`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarComuna(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionComunas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarOficina(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionOficinas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarUsuarios(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionUsuarios`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarUsuariosOfi(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionUsuOficinas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarLockers(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionLockers`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarCasillero(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionCasilleros`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async AdministracionBodegas(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdminBodega`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async eliminarZona(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionZonas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crudZonas(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionZonas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crudRegiones(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionRegiones`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crudComunas(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionComunas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crudOficinas(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionOficinas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crudClientes(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionClientes`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crudUsuarios(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionUsuarios`;
    try {
      return await this.httpClient
      .post(url, req, { headers: this.headers })
      .toPromise();
    } catch (error) {
      return {status: false, code: 804, message: 'Error al ejecutar la petición.',};
    }
  }

  async crudUsuariosOfi(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionUsuOficinas`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crudLockers(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/AdministracionLockers`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerRegion(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'REG',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerComuna(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'COM',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerUsuariosOfi(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Usuarios/GetUsuarios`;
    const request = {
      tipo: 'USO',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerLockers(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'LOC',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async nuevoPedido(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Flujo/nNewPedido`;
    const request = {
      tipo: 'N',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerComunas(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'COM',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerOficinas(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'OFI',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerClientes(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'CLI',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async TraerUsuario(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'USU',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async IngresarPedido(request: NuevosPedidos): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Flujo/NuevoPedido`;
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async RetirarPedido(request: RetirarPedido): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Flujo/SacarPedido`;
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async traerCasilleros(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Administrar/GetDatos`;
    const request = {
      tipo: 'CAS',
    };
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async loginUsuario(request: login): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Login/InicioSesion`;
    try {
       return await this.httpClient
         .post(url, request, { headers: this.headers })
         .toPromise();
      // return {
      //   status: true,
      //   code: 200,
      //   message: 'Error al ejecutar la petición.',
      // };
      
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  //-------------------------------- MEthanet--------------------------

  async TraerTipoElemento(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}TiposElementoController/GetTiposElemento`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async TraerClientesMethanext(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Usuarios/GetUsuarios`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }
 
  // Metodos aministrador de clientes
  // Obtener clientes

  // traerClientesAdmin():Observable<AdminClientes> {
  //   const API_URL = this.baseURLLocker;
  //   const url = `${this.url_TEST}Clientes/GetClientes`
  //   return  this.httpClient.get<AdminClientes>(url)
  // }
  async traerClientesAdmin(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Clientes/GetClientes`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }
  // Obtener cliente
  traerClienteAdmin(rut:string):Observable<UsuariosAdm>{
   
    const API_URL = this.baseURLLocker;
    const url = `${this.url_TEST}Clientes/GetCliente?rutCliente=${rut}`;
    return this.httpClient.get<UsuariosAdm>(url)
  }
  
  // Insertar
  // agregarClienteAdmin(cliente: UsuariosAdm): Observable<AdminClientes>{
  //   const API_URL = this.baseURLLocker;
  //   const url = `${API_URL}Clientes/CrearCliente`;
  //   return this.httpClient.post<AdminClientes>(url, cliente)
  // }
  async agregarClienteAdmin(cliente: UsuariosAdm): Promise<any>{
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Clientes/CrearCliente`;
    try {
      return await this.httpClient
        .post(url, cliente, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  // Actualizar

  // actulizarClienteAdmin(cliente: any):Observable<UsuariosAdm>{
  //   const API_URL = this.baseURLLocker;
  //   const url = `${API_URL}Clientes/EditarCliente`;
  //   return this.httpClient.post<UsuariosAdm>(url, cliente)
  // }

  async actulizarClienteAdmin(cliente: UsuariosAdm):Promise<any>{
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Clientes/EditarCliente`;
    try{
      return await this.httpClient
      .post(url,cliente,{headers: this.headers})
      .toPromise();
    }catch(error){
      return{
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  // Eliminado lógico
  // eliminarClienteAdmin(id: any):Observable<any>{
  //   const API_URL = this.baseURLLocker;
  //   const url = `${API_URL}Clientes/EliminarCliente?rut=${id}`;
  //   return  this.httpClient.get<UsuariosAdm>(url)
  // }
  async eliminarClienteAdmin(id: any):Promise<any>{
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Clientes/EliminarCliente?rut=${id}`;
    try {
      return await this.httpClient
      .get(url, { headers: this.headers })
      .toPromise();
    } catch (error) {
      return{
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      }
    }
  }

  // Termino métodos de aministrador clientes

  // métodos de usuarios lockers Santander
  async obtenerUsuarios(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Usuarios/GetUsuarios`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async crearUsuario(req: any): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Usuarios/CrearUsuario`;
    try {
      return await this.httpClient
      .post(url, req, { headers: this.headers })
      .toPromise();
    } catch (error) {
      return {status: false, code: 804, message: 'Error al ejecutar la petición.',};
    }
  }

  async actualizarUsuarios(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Usuarios/EditarUsuario`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }
// Termino métodos de aministrador usuarios

  async TraerRoles(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Roles/GetRoles`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async TraerOficinas(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Oficinas/GetOficinas`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }


  async AgregarClientesMethanext(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Usuarios/CrearUsuario`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async EliminarClientes(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Usuarios/EliminarUsuario?idUsuario=`+req;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async EliminarOficinaMethanext(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Oficinas/EliminarOficina?idOficina=`+req;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarCliente(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Usuarios/EditarUsuario`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async EliminarTipoElemntos(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}TiposElementoController/EliminarTipoElemento?idTipoElemento=`+req;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async EliminarElementos(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Elementos/EliminarElemento?idElemento=`+req;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarTipoElemntos(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}TiposElementoController/EditarTipoElemento`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async AgregarTipoElemntos(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}TiposElementoController/CrearTipoElemento`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }


  // Elementos

  async LiberarCasillero(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}v1/Flujo/LiberarCasillero`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ListarElemntos(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Elementos/GetElementos`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async GetDatoadeldia(data): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}v1/Info/LockerStatus`;
    try {
      return await this.httpClient
        .post(url,data, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async AgregarElemntos(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Elementos/CrearElemento`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async EditarElemntos(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Elementos/EditarElemento`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  

  async AgregarOficinasMethanext(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Oficinas/CrearOficina`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  async ActualizarOficinaMethanext(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Oficinas/EditarOficina`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }
  async actualizarOficina(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}Oficinas/EditarOficina`;
    try {
      return await this.httpClient
        .post(url, req, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

   //----- lista de casilleros
   async Listacasilleros(req): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['DatosMethanex']}v1/Flujo/GetCasillerosPorLocker?locker=` + req;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  obtenerSucursales(): Observable<any>{
    const API_URL = this.baseURL;
    const url = `${API_URL}Oficinas/GetOficinas`
    return this.httpClient.get(url,{ headers: this.headers });

  }


  async BusquedaPedidoCliente(rut): Promise<any>{
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Flujo/Consultadatoscliente?rut=${rut}`;
    try {
      return await this.httpClient
        .get(url, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }

  }
  
}
