<!-- main app container -->
<div class="container-fluid overflow-auto mt-3" style="height: 62vh!important;">
    <h5 class="card-header" class="text-secondary">{{title}} Usuarios</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary" *ngIf="registerForm">

            <div class="container">
                <div class="form-group">
                    <label for="dniUsu">Rut</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="dniUsu"><i class="fas fa-id-card"></i></span>
                        </div>
                        <input type="text" class="form-control" name="dniUsu" formControlName="dniUsu"
                            [ngClass]="{ 'is-invalid': formDirtyRequire('dniUsu')}" (keyup)="setRut()" disabled>
                    </div>
                    <div *ngIf="formTouchLenght('dniUsu')">
                        <div *ngIf="formErrors('dniUsu')">
                            <small class="text-danger">
                                Por favor ingresa un minimo de 8 digitos
                            </small>
                        </div>
                    </div>
                    <div *ngIf="formTouched('dniUsu')">
                        <div *ngIf="formErrors('dniUsu')">
                            <small class="text-danger">
                                Por favor ingresa un rut válido con un minimo de 8 digitos
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="container">
                <div class="form-group">
                    <label for="sPassusu">Contraseña</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="sPassusu"><i class="fa fa-lock"></i></span>
                        </div>
                        <input type="password" formControlName="sPassusu" class="form-control"
                            [ngClass]="{ 'is-invalid': formDirty('sPassusu')}">
                    </div>
                </div>
            </div>

            <!-- <div class="container">
                <div class="form-group">
                    <label for="nomUsu">Nombre</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="nomUsu"><i class="fas fa-user"></i></span>
                        </div>
                        <input type="text" class="form-control" formControlName="nomUsu"
                            [ngClass]="{ 'is-invalid': formDirty('nomUsu')}">
                    </div>

                    <div *ngIf="formTouched('nomUsu')">
                        <div *ngIf="campoRequerido('nomUsu')">
                            <small class="text-danger">
                                Por favor ingresa un nombre.
                            </small>
                        </div>

                        <div *ngIf="minLargo('nomUsu')">
                            <div *ngIf="minLargoRequerido('nomUsu')">
                                <small class="text-danger">
                                    Nombre debe tener 3 caracteres como mínimo.
                                </small>
                            </div>
                        </div>

                        <div *ngIf="maxLargo('nomUsu')">
                            <div *ngIf="maxLargoRequerido('nomUsu')">
                                <small class="text-danger">
                                    Nombre debe tener 100 caracteres como máximo.
                                </small>
                            </div>
                        </div>

                        <div *ngIf="caracterEspecial('nomUsu')">
                            <small class="text-danger">
                                Nombre no debe contener caracteres especiales.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="form-group">
                    <label for="bHab">Estado</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="bHab"><i class="fa fa-toggle-on"></i></span>
                        </div>
                        <select class="form-control"  formControlName="bHab" id="bHab">
                            <option value="" disabled>Seleccionar estado</option>
                            <option *ngFor="let est of Estado" >{{est.estado}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="form-group">
                    <label for="idRol">Rol</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="idRol"><i class="fa fa-solid fa-check"></i></span>
                        </div>
                        <select class="form-control" formControlName="idRol" id="idRol">
                            <option value="" disabled>Seleccionar rol</option>
                            <option *ngFor="let rol of roles.data" >{{rol.descripcion}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="form-group">
                    <label for="idOficina">Oficina</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="idOficina"><i class="fa fa-solid fa-check"></i></span>
                        </div>
                        <select class="form-control"  formControlName="idOficina" id="idOficina">
                            <option value="" disabled>Seleccionar oficina</option>
                            <option *ngFor="let oficina of oficinas.dataOfi" >{{oficina.sOficina}}</option>
                        </select>
                    </div>
                </div>
            </div> -->

            <div class="text-center mt-5">
                <button class="btn btn-primary mr-1" type="submit" (click)="onSubmit(false)"
                    [disabled]="registerForm.invalid">
                    GUARDAR
                </button>
                <button class="btn btn-secondary" (click)="onSubmit(true)">
                    CANCELAR
                </button>
            </div>
        </form>
    </div>
</div>