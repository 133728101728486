import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { TarjetaLockerService } from '../../core/services/http/tarjeta-locker.service';
import { RutService } from 'src/app/core/services/http/rut.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-menu-opciones',
  templateUrl: './menu-opciones.component.html',
  styleUrls: ['./menu-opciones.component.scss']
})
export class MenuOpcionesComponent implements OnInit, OnDestroy {
  total: [] = [];
  Disponibles: [] = [];
  Usados: [] = [];
  Expirados: [] = [];
  idoficina;
  idLocker;
  ConsulOfi = true;
  rolsesion;
  rutValid: boolean = false;
  logs;
  filteredValues: any;
  lockersV2: [] = [];
  pedidos: any[];
  
  registerForm: FormGroup;

  constructor(
    private router: Router,
    private PruebasService: PruebasService,
    private SweetAlertService: SweetAlertService,
    private rutService: RutService,
    private formBuilder: FormBuilder,
    private TarjetaLockerService: TarjetaLockerService) { }

  async ngOnInit(): Promise<void> {

    this.registerForm = this.formBuilder.group({
      dniUsu: ['', [Validators.required, Validators.minLength(8)]]
    })

    this.rolsesion = localStorage.getItem("rolsesion");
    const usuario = JSON.parse(localStorage.getItem("usulogeado"));
    this.idoficina = usuario["idOficina"]
    var resplocker = await this.TarjetaLockerService.DibujoCasillero(this.idoficina);
    this.idLocker = resplocker["data"][0]["idLocker"]
    await this.traerDatosElementos();
    this.consultarCambioOficina();
    this.llenarTabla();
  }

  async traerDatosElementos() {
    this.SweetAlertService.swalLoading();
    var data = {
      "idOficina": this.idoficina,
      "idLocker": this.idLocker
    }
    //console.log(data);
    let response = await this.PruebasService.GetDatoadeldia(data);
    //console.log(response);
    if (response["status"]) {
      this.total = response["data"]["total"];
      this.Disponibles = response["data"]["libres"];
      this.Usados = response["data"]["usados"];
      this.Expirados = response["data"]["vencidos"]
    }
    else {
      this.SweetAlertService.swalError("no se puede crear elementos en este momento, vuelva a intentar");
    }
    this.SweetAlertService.swalClose();
  }

  async consultarCambioOficina() {
    if (this.ConsulOfi == true) {
      if (this.idoficina != localStorage.getItem("ofiselect")) {
        this.idoficina = localStorage.getItem("ofiselect")
        var resplocker = await this.TarjetaLockerService.DibujoCasillero(this.idoficina);
        this.idLocker = resplocker["data"][0]["idLocker"];
        this.traerDatosElementos();
      }
      setTimeout(() => {
        this.consultarCambioOficina();
      }, 1100);

    }
  }

  // cliente metodos 

  setRut(): void {
    this.rutValid = this.rutService.validarRut(
      this.registerForm.value.dniUsu.replace(/\./g, '').replace('-', '')
    );
    this.registerForm.patchValue({
      dniUsu: this.rutService.formateaRut(this.registerForm.value.dniUsu),
    });

    if (this.rutValid) {
      // Validando que el rut ingresado contenga sólo números y la letra K
      const rutRegex = /^\d{1,3}(?:\.\d{1,3}){2}-[\dkK]$/;
      this.rutValid = rutRegex.test(this.registerForm.value.dniUsu);
    }
  }

  validaRut(): void {
    this.rutValid = this.rutService.validarRut(this.registerForm.value.rut.replace(/\./g, '').replace('-', ''));
    this.registerForm.patchValue({ rut: this.rutService.formateaRut(this.registerForm.value.registerForm) })
  }

  formDirtyRequire(dato: any) {
    return this.registerForm.get(dato).errors?.dirty && this.registerForm.get(dato).errors?.required
  }

  formTouchLenght(dato: any) {
    return this.registerForm.get(dato).touched && this.registerForm.get(dato).errors?.minLength
  }
  formTouched(dato: any) {
    return this.registerForm.get(dato)?.touched
  }

  async onSubmit(){
    var resp = await this.PruebasService.BusquedaPedidoCliente(this.registerForm.value.dniUsu);
    // //console.log('119 ',resp.data);
    if (resp["status"] == true) {
      this.logs = resp.data
      this.SweetAlertService.BusquedaCLiente("Cliente encontrado",resp.message);
    }
    else{
      this.SweetAlertService.BusquedaCLiente("Cliente No Encontrado",resp.message);
    }
    // //console.log('Enviando');
  }
//------------------------
  ngOnDestroy(): void {
    this.ConsulOfi = false;
  }



  async llenarTabla()
  {
    this.SweetAlertService.swalLoading();
    let response = await this.PruebasService.Listacasilleros(this.idLocker);
    if (response['status']) {
      this.lockersV2 = response['data'].filter(item => item.observacion !== null);
      this.filteredValues = this.lockersV2;
    }
    this.SweetAlertService.swalClose();
  }
 
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }

  exportExcel(){
    var datos = [];
    var toExport = [];
    if(this.filteredValues)
    {
      datos = this.filteredValues;
      for (let element of datos) {
      var obj = {
        'ID OFicina':element.idOficina,
        'idLocker':element.idLocker,
        'Cargador':element.nombre,
        'Rut cliente':element.rutCliente,
        'Nombre': element.nombreLocker,
        'Email': element.correo,
        'Teléfono':element.telefono,
        'Fecha':element.fecha,
        'Observación':element.observacion
        }
      toExport.push(obj);
      }
      import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(toExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Vencidos");
      });
    }
    else
    {
      datos = this.lockersV2;
      for (let element of datos) {
      var obj = {
        'ID OFicina':element.idOficina,
        'idLocker':element.idLocker,
        'Cargador':element.nombre,
        'Rut cliente':element.rutCliente,
        'Nombre': element.nombreLocker,
        'Email': element.correo,
        'Teléfono':element.telefono,
        'Fecha':element.fecha,
        'Observación':element.observacion
        }
      toExport.push(obj);
      }
      import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(toExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Vencidos");
      });
    }
  }

}
