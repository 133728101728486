import { Component, OnInit } from '@angular/core';
import { UploadService } from '../../../../../core/services/http/upload.service';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmitUploadService } from '../../../../../core/services/emit/emit-upload.service';
import { SweetAlertService } from '../../../../../core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-elemento-upload',
  templateUrl: './elemento-upload.component.html',
  styleUrls: ['./elemento-upload.component.scss']
})
export class ElementoUploadComponent implements OnInit {

  uploadingFile: boolean;
  myform: FormGroup;
  clienteUpload: FormControl;
  clientePlaceholder : string = "Seleccionar archivo";
  file: File;

  constructor(
    private _uploadService: UploadService,
    private _emitUpload:EmitUploadService,
    private SweetAlertService: SweetAlertService
  ) { }

  ngOnInit(): void {
    this.createFormControls();
    this.createForm();
    window.dispatchEvent(new Event('resize'));
  }

  createFormControls() {
    this.clienteUpload = new FormControl(Validators.required);
  }
  createForm(){
    this.myform = new FormGroup({
      clienteUpload: this.clienteUpload
    })
  }

  async fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length === 0) {
        return;
    }

    let file: File = fileList[0];
    this.uploadingFile = true;
    const resp = (await this._uploadService.uploadFileElementos(file)).subscribe(
        result => {
            if (result instanceof HttpResponse) {
                this.SweetAlertService.swalSuccess("Éxito", "El archivo ha sido cargado exitosamente.");
                //console.log("RESPUESTA: ", resp );
                
            }
        },
        error => {
            console.error(error);
            this.uploadingFile = false;
            this.SweetAlertService.swalError("No se pudo cargar el archivo, intente nuevamente.");
        },
        () => {
            this.uploadingFile = false;
        }
    )
}

}
