<form novalidate [formGroup]="myform" *ngIf="myform">
<div class="box">
  <div class="box-header with-border">
      <h3 class="box-title">Clientes</h3>
  </div>
  <div class="box-body">
      <div class="form-group">
          <label>Seleccione el archivo a subir</label>
          <input type="file"  formControlName="clienteUpload" (change)="fileChange($event)" placeholder="Subir archivo" accept=".xls,.xlsx">
      </div>
      <div class="form-group" *ngIf="uploadingFile">
          <i class="fa fa-spin fa-refresh fa-2x"></i>
          Subiendo archivo, por favor espere...
      </div>
  </div>
</div>
</form>