<!-- <div class="container" style="position: relative; top: 100px;">
    <div class="container-fluid overflow-auto d-flex justify-content-center align-items-center" style="height: 87vh!important;" style="color: black;">
        <form class="form-horizontal" role="form" [formGroup]="myGroup" style="width: 100%;">
            <h2 class="d-flex justify-content-center">INICIAR SESION</h2>
            <br>
            <div class="d-flex justify-content-center" style="position: relative; width: 500px; left: 225px;">
                <label for="usuarioRut" class="col-sm-3 control-label">*RUT</label>
                <div class="col-sm-9" style="width: 500px;">
                    <input type="string" formControlName="usuarioRut" id="usuarioRut" placeholder="12345678-9" maxlength="10"
                        class="form-control" autofocus>
                </div>
            </div>
            <div class="d-flex justify-content-center" style="position: relative; width: 500px; left: 225px;">
                <label for="usuarioPass" class="col-sm-3 control-label">*CONTRASEÑA</label>
                <div class="col-sm-9">
                    <input type="password" formControlName="usuarioPass" id="usuarioPass" placeholder="*****" class="form-control"
                        autofocus>
                </div>
            </div>
            <br>
            <div class="d-flex justify-content-center" style="position: relative; width: 500px; left: 380px;">
                <div class="col-sm-9 col-sm-offset-3">
                    <span class="help-block">*Campos obligatorios</span>
                </div>
            </div>
            <br>
            <div class="d-flex justify-content-center" style="position: relative; width: 500px; left: 285px;">
                <button type="submit" class="btn btn-outline-primary" (click)="ValidaLogin()" onClick="this.disabled=true">Ingresar</button><br>
            <button type="submit" class="btn btn-outline-secondary" (click)="salir()" onClick="this.disabled=true">Salir</button>
            </div>
        </form>  /form -
      </div> ./container 
</div>
 -->
<div class="hold-transition login-page">
    <div class="login-box">
        <div class="login-logo">
            <img width="100%" src="assets/img/LOGO-SANTANDER.png">
        </div>
        <!-- /.login-logo -->

        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Bienvenido</p>

                <form role="form" [formGroup]="myGroup">
                    <div class="input-group mb-3">
                        <input type="text" maxlength="12" 
                                [ngClass]="{'is-invalid': submited && !rutValid}" formControlName="usuarioRut" 
                                id="usuarioRut" class="form-control" placeholder="12.345.678-9" 
                                (keyup)="validaRut()">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="EsSubmit()">
                        <small class="text-danger">El rut ingresado no es válido</small>
                    </div>
                    <div class="input-group mt-3">
                        <input type="password" formControlName="usuarioPass" 
                               [ngClass]="{'is-invalid': submited && myGroup.get('usuarioPass').errors}" 
                               id="usuarioPass" class="form-control"
                               placeholder="******">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="groupSubmit('usuarioPass')">
                        <small class="text-danger">La constraseña no puede queda vacía</small>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="icheck-primary">
                                <input type="checkbox" id="remember">
                                <label for="remember" style="position: relative; left: 5px;">
                                    Recordarme
                                </label>
                            </div>
                        </div>
                        <!-- /.col -->
                        <div class="col-12" (click)="ValidaLogin()">
                            <a class="btn btn-block btn-success w-100">
                                Iniciar sesión<i class="fas fa-sign-in-alt ml-2"></i>
                            </a>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>


            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</div>