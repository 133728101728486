import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ReporteriaService } from 'src/app/core/services/http/reporteria.service';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { RutService } from 'src/app/core/services/http/rut.service';
import { ModalLogComponent } from '../modal-log/modal-log.component';
//exportar a excel
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
//exportar a pdf
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-reporte-log',
  templateUrl: './reporte-log.component.html',
  styleUrls: ['./reporte-log.component.scss']
})
export class ReporteLogComponent implements OnInit {

  dataDia: any[] = [];
  nameTabla!: string;
  columns: any[] = [];
  exportColumns: any[];
  cols: any[];
  idSesion = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  fileName= 'ReporteLOG.xlsx';

  constructor(
    private sweetAlertService: SweetAlertService,
    private reporteriaService: ReporteriaService,
    private datePipe: DatePipe,
    private rutService: RutService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.sweetAlertService.swalLoading();
    this.getColumnas();
  }

  getColumnas(): void {
    this.columns = [
      { title: 'ID Sesión', data: 'idSesion'},
      { title: 'ID Locker', data: 'idLocker'},
      { title: 'Locker', data: 'sNomLocker'},
      { title: 'ID Oficina', data: 'idOficina' },
      { title: 'Oficina', data: 'sOficina' },
      { title: 'Casillero', data: 'casillero' },
      { title: 'Fecha', data: 'fecha', render: (data: any): any => this.datePipe.transform(data, 'dd/MM/yyyy') },
      { title: 'Hora', data: 'hora', className: 'dt-center' },
      { title: 'Descripción', data: 'descripcion', orderable: false },
      { title: 'Usuario', data: 'rutCarrier', width: '8%', render: (data: any): any => data ? this.rutService.formateaRut(data) : data },
      { title: 'Cliente', data: 'rutDestinatario', width: '8%', render: (data: any): any => data ? this.rutService.formateaRut(data) : data },
      { title: 'Tipo Operación', data: 'tipoOperacion' },
      { title: 'Estado Operación', data: 'estadoOperacion', width: '10%', className: 'dt-center', render: (data): any => {
          const icon = data == "Correcto" ? '<i class="fas fa-check-circle text-success"></i>' : '<i class="fas fa-times-circle text-danger"></i>';
          const button = `<span class="d-none">${data ? 'Correcto' : 'Error'}</span> ${icon}`;
          return button;
        }
      },
    ];
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

   async getLogs(data: any): Promise<void> {
     this.sweetAlertService.swalLoading();
     this.dataDia = [];

     const response = await this.reporteriaService.ReporteriaLOG(data);
     //console.log("DATA: ", data);
     
     
     this.sweetAlertService.swalClose();

     if (response['status']) {
       this.dataDia = response['data']; 
       const totem = data.idTotem === 0 ? '' : `_Kiosko-${data.idTotem}`;
       this.nameTabla = `Reporte de LOG_Oficina-${data.fechaIn}_${data.fechaFin}`;
     }
   }

  action(e: any): void {
    if (e.action === 'log') {
      this.detalleLog(JSON.parse(e.value));
    }
  }

  detalleLog(data: any): void {
    const options = {
      class: 'modal-xl',
      ignoreBackdropClick: true,
      initialState: { data }
    };
    this.modalService.openModal(ModalLogComponent, options);
  }
  //EXPORTAR A EXCEL (SE FILTRAN LOS DATOS A IMPRIMIR)
  exportExcel() {
    var datos = [];
    var toExport = [];
    datos = this.dataDia;
    for (let element of datos) {
      var obj = {
        'ID Oficina':element.idOficina,
        'Casillero': element.casillero,
        'Fecha': element.fecha,
        'Descripción': element.descripcion,
        'Usuario': element.rutCarrier,
        'Tipo Operación': element.tipoOperacion,
        'Estado Operación': element.estadoOperacion
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(toExport);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "ReporteLOG");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  //EXPORTAR A PDF
  exportPdf() {
    var datos = [];
    var toExport = [];
    datos = this.dataDia;
    for (let element of datos) {
      var obj = {
        'ID Oficina':element.idOficina,
        'Casillero': element.casillero,
        'Fecha': element.fecha,
        'Descripción': element.descripcion,
        'Usuario': element.rutCarrier,
        'Tipo Operación': element.tipoOperacion,
        'Estado Operación': element.estadoOperacion
      }
      toExport.push(obj);
    }
     var doc = new jsPDF('p', 'pt');
     doc.text("Reporte LOG",50, 30);
      autoTable(doc, {columns: this.exportColumns,body: toExport})
      doc.save('ReporteLOG.pdf');
  }
}
