<!-- main app container -->
<div class="container-fluid overflow-auto mt-3" style="height: 62vh!important;">
    <h5 class="card-header" class="text-secondary">{{title}} Clientes</h5>
    <div class="card-body">
        <form [formGroup]="formulario" 
              class="text-secondary" 
              *ngIf="formulario"
              (ngSubmit)="onSubmit()"
              >
            <!-- <div class="form-group" *ngIf="ListaRoles">
                <label for="exampleInputZone">ID Rol</label>
                <select class="form-control" 
                        [ngClass]="{'is-invalid': submitted && registerForm.get('sIdRol').errors}" 
                        formControlName="sIdRol" >
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option *ngFor="let Rol of ListaRoles" [ngValue]="Rol.idRol">
                        {{ Rol.descripcion }}</option>
                </select>
                <div *ngIf="submitted && registerForm.get('sIdRol').errors">
                    <small class="msg-error">Seleccione un rol</small>
                </div>
            </div> -->

            <div class="container">
                <div class="form-group">
                  <label for="rut">Rut</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="rut"><i class="fas fa-id-card"></i></span>
                    </div>
                    <input type="text" 
                        class="form-control" 
                        name="rut"
                        formControlName="rut"
                        [ngClass]="{ 'is-invalid': formDirtyRequire('rut')}"
                        (keyup)="setRut()">
                  </div>
                  <div *ngIf="formTouchLenght('rut')">
                    <div *ngIf="formErrors('rut')">
                      <small class="text-danger">
                        Por favor ingresa un minimo de 8 digitos
                      </small>
                    </div>
                  </div>
                <div *ngIf="formTouched('rut')">
                    <div *ngIf="formErrors('rut')">
                      <small class="text-danger">
                        Por favor ingresa un rut válido con un minimo de 8 digitos
                      </small>
                    </div>
                </div>
                </div>
              </div>

            <div class="container">
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="nombre"><i class="fas fa-user"></i></span>
                    </div>
                    <input type="text" class="form-control" formControlName="nombre"
                      [ngClass]="{ 'is-invalid': formDirty('nombre')}">
                  </div>
      
                  <div *ngIf="formTouched('nombre')">
                    <div *ngIf="campoRequerido('nombre')">
                      <small class="text-danger">
                        Por favor ingresa un nombre.
                      </small>
                    </div>
      
                    <div *ngIf="minLargo('nombre')">
                      <div *ngIf="minLargoRequerido('nombre')">
                        <small class="text-danger">
                          Nombre debe tener 2 caracteres como mínimo.
                        </small>
                      </div>
                    </div>
      
                    <div *ngIf="maxLargo('nombre')">
                      <div *ngIf="maxLargoRequerido('nombre')">
                        <small class="text-danger">
                          Nombre debe tener 100 caracteres como máximo.
                        </small>
                      </div>
                    </div>
      
                    <div *ngIf="caracterEspecial('nombre')">
                      <small class="text-danger">
                        Nombre no debe contener caracteres especiales.
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="form-group">
                  <label for="email">Correo electrónico</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="email"><i class="fas fa-envelope"></i></span>
                    </div>
                    <input type="text" class="form-control" formControlName="email"
                      [ngClass]="{ 'is-invalid': formDirty('email') }">
                  </div>
      
                  <div *ngIf="formTouched('email')">
                    <div *ngIf="campoRequerido('email')">
                      <small class="text-danger">
                        Por favor ingresa un email.
                      </small>
                    </div>
      
                    <div *ngIf="caracterEspecial('email')">
                      <small class="text-danger">
                        Por favor ingresa un email válido.
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="form-group">
                  <label for="telefono">Teléfono (912345678)</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="telefono"><i class="fas fa-phone-alt"></i></span>
                    </div>
                    <input type="text" class="form-control" formControlName="telefono"
                      [ngClass]="{ 'is-invalid': formDirty('email') }" >
                  </div>
      
                  <div *ngIf="formTouched('telefono')">
                    <div *ngIf="campoRequerido('telefono')">
                      <small class="text-danger">
                        Por favor ingresa un telefono.
                      </small>
                    </div>
      
                    <div *ngIf="caracterEspecial('telefono')">
                      <small class="text-danger">
                        Por favor ingresa un telefono válido.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            <!-- <div class="form-group">
                <label for="exampleInputZone">Clave</label>
                <input type="text" [ngClass]="{'is-invalid': submitted && registerForm.get('sClave').errors}" formControlName="sClave" class="form-control" >
                <div *ngIf="submitted && registerForm.get('sClave').errors">
                    <small class="msg-error">Ingrese una clave</small>
                </div>
            </div> -->
            <!-- <div class="form-group" *ngIf="ListaOficinas">
                <label for="exampleInputZone">Oficina</label>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('idOfi').errors}" formControlName="idOfi" >
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option value="1" >--Sin oficina--</option>
                    <option *ngFor="let Ofi of ListaOficinas" [ngValue]="Ofi.idOficina">{{ Ofi.sOficina }}</option>
                </select>
                <div *ngIf="submitted && registerForm.get('idOfi').errors">
                    <small class="msg-error">Seleccione una Oficina</small>
                </div>
            </div> -->
            <!-- <div class="form-group">
                <label for="exampleInputZone">Estado</label><br>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('eUsuario').errors}" 
                formControlName="eUsuario" placeholder="Selecciona">
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option value="true">Habilitado</option>
                    <option value="false">Deshabilitado</option>
                </select>
                <div *ngIf="submitted && registerForm.get('eUsuario').errors">
                    <small class="msg-error">Seleccione un estado</small>
                </div>
            </div>            -->
            <div class="text-center mt-5">
      
                <button class="btn btn-primary mr-1" type="submit" (click)="onReset()" [disabled]="formulario.invalid">GUARDAR</button>
                <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
            </div>
        </form>
    </div>

</div>