import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }
  izq = false;
  ngOnInit(): void {
    var reiniciado =  localStorage.getItem("Reiniciado");
    if (reiniciado == "no") {
      localStorage.setItem("Reiniciado","si")
      window.location.reload();
    }
  }

  LoginUsuario(e: any): void {
    e.preventDefault();
    this.router.navigate(['LoginUser']);
  }

  // izqbtn() {
  //   this.izq = true;
  // }

  // derbtn() {
  //   if (this.izq == true) {
  //     this.router.navigate(['admin']);
  //   }
  //   else{
  //     this.izq = false;
  //   }
  // }


}
