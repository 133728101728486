<div class="content-wrapper">
    <div class="container-fluid main">
      <div class="fade-in">
        <div class="card">
          <div class="card-header">
    
            <div class="row">
              <div class="col-12 text-center">
                <h4 class="text-dark">Reporte de uso Dispositivos</h4>
    
                <app-header-table
                (changeAction)="getTransaction($event)">
                </app-header-table>
                <div class="row">
                  <!-- <button (click)="exportExcel()" *ngIf="dataCierre.length > 0">Exportar a Excel</button>
                  <button (click)="exportPdf()" *ngIf="dataCierre.length > 0">Exportar a PDF</button> -->
                </div>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center display-4" *ngIf="dataCierre.length === 0">
                <h4> Sin registros de Uso Dispositivos </h4>
              </div>
    
              <div id="excel-table" class="col-sm-12 table-responsive" *ngIf="dataCierre.length > 0">
                <app-datatable [dtOptions]="dtOptions"
                  [columnas]="columns"
                  [data]="dataCierre"
                  [nameTabla]="nameTabla">
                  [filterable]="true"
                </app-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

