import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class EmitUploadService {

  @Output() refrescar: EventEmitter<any> = new EventEmitter();

  constructor() { }
  
  refrescarLista(){
    this.refrescar.emit('ok');
  }
}
