import { Component } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { ConfigService } from './core/services/config/config.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  urlconfig =null;
  constructor( private authService: AuthService,private ConfigService:ConfigService ) {
    this.authService.idleUser();
    this.obtenerconfig();
  }

  ubicarcionUrl(){
    return window.location.pathname;
  }

  async obtenerconfig(){
    var config = await this.ConfigService.getConfig();
    this.urlconfig = config["url"];
    //console.log(this.urlconfig);
    
  }
}
