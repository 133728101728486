import { Injectable } from '@angular/core';
import { DataProcess } from '../../models/data-process';

@Injectable({
  providedIn: 'root'
})
export class DataProcessService {

  private dataProcess: any = new DataProcess();

  constructor() { }

  getDataProcess(): DataProcess {
    return this.dataProcess;
  }

  addDataProcess(property: string, data: any): void {
    this.dataProcess[property] = data;
  }

  deleteDataProcess() {
    delete this.dataProcess.Usuario;
  }
}
