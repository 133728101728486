<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <h5 class="card-header" class="text-secondary">Añadir Locker</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary">
            <div class="form-group">
                <label for="exampleInputZone">ID Locker</label>
                <input type="number"  formControlName="idLocker" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">ID Oficina</label>
                <input type="number"  formControlName="idOficina" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Numero de Puertas</label>
                <input type="number"  formControlName="iNumPuertas" class="form-control" >
            </div>   
            <div class="form-group">
                <label for="exampleInputZone">Nombre de Locker</label>
                <input type="text"  formControlName="nomLocker" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Dirección</label>
                <input type="text"  formControlName="direccion" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">ID Comuna</label>
                <input type="number"  formControlName="comuna" class="form-control" >
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Estado</label><br>
                <select class="form-control" formControlName="bHab">
                    <option value="true">Habilitado</option>
                    <option value="false">Deshabilitado</option>
                </select>
            </div>              
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarLockers()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>