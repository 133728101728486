<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-auto">
          <h1 class="m-0 pl-4 subtitle">Módulo Oficinas</h1>
        </div>
        <div class="col-sm-6 mt-1 border-left border-right">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a href="#">Admin Oficinas</a></li>
            <li class="breadcrumb-item active">Oficinas</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Main row -->
      <div class="row">
        <!-- Left col -->
        <section class="col-lg-12 pl-3 pr-3 connectedSortable">
          <div class="card  box">
            <div class="row p-3">
              <div class="col-12 col-md-8">
                <h5>Asignar Oficinas</h5>
              </div>
              <div class="col-6 col-md-4 text-right">
                <button type="button" (click)="Seleccionar()" 
                        class="btn btn-block btn-success" 
                        *ngIf="sesionrol == 1">
                  <i class="fas fa-cogs"></i> 
                  Agregar Nueva Oficina
                </button><br><br>
              </div>
              <div class="col-12">
                <!-- table table-responsive table-hover mt-2 -->
                <div *ngIf="oficinasV2" style="height: 700px;">

                  <p-table #dt1 [value]="oficinasV2" (onFilter)="onFilter($event, dt)" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
                    styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [globalFilterFields]="['idOficina','sOficina', 'sCorreo', 'bEstado']">
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        Listar Clientes
                        <div class="row">
                          <button class="btn btn-success" (click)="exportExcel(dt1['filteredValue'])" *ngIf="oficinasV2.length > 0" style="position: relative; left: 320px;"><i class="far fa-file-excel"></i> Exportar a Excel</button>
                          <button class="btn btn-warning" (click)="exportPdf(dt1['filteredValue'])" *ngIf="oficinasV2.length > 0"><i class="far fa-file-pdf"></i> Exportar a PDF</button>
                        </div>
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar en Tabla" />
                        </span>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      
                        <tr>
                          <th>
                            <div class="text-center" style="background: #f8f9fa;">N° Oficina</div>
                          </th>
                          <th>
                            <div class="text-center" style="background: #f8f9fa;">Cod oficina</div>
                          </th>
                          <th>
                            <div class="text-center" style="background: #f8f9fa;">Nombre</div>
                          </th>
                          <th>
                            <div class="text-center" style="background: #f8f9fa;">Email</div>
                          </th>
                          <th *ngIf="sesionrol != 2">
                            <div class="text-center" style="background: #f8f9fa;">Acciones</div>
                          </th>
                          <!-- <th *ngIf="sesionrol != 2">
                            <div class="p-d-flex p-jc-between p-ai-center">Eliminar</div>
                          </th> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer let-i = "rowIndex">
                      <tr>
                        <td class="text-center">
                          {{customer.idOficina}}
                        </td>
                        <td pEditableColumn class="text-center">
                          {{customer.sCodOfi}}
                        </td>
                        <td pEditableColumn class="text-center">
                          {{customer.sOficina}}
                        </td>
                        <td pEditableColumn class="text-center">
                          {{customer.sCorreo}}
                        </td>
                        <!-- <td>
                          <select class="form-control w-100" [(ngModel)]="customer.desde">
                              <option selected style="color: red;" disabled>{{customer.desde}}</option>
                              <option *ngFor="let Ofi of numeros" [ngValue]="Ofi"> {{Ofi.num}}</option>
                          </select>
                        </td>
                        <td>
                          <select class="form-control w-100"
                          [(ngModel)]="customer.hasta">
                              <option selected style="color: red;" disabled>{{customer.hasta}}</option>
                              <option *ngFor="let Ofi of numeros" [ngValue]="Ofi"> {{Ofi.num}}</option>
                          </select>
                        </td> -->
                        <!-- <td>
                          <select class="form-control w-100">
                              <option value="true">Habilitado</option>
                              <option value="false">Deshabilitado</option>
                          </select>
                        </td> -->
                        <td class="text-center" *ngIf="sesionrol ==1">
                          <!-- <i class="fas fa-edit" style="cursor: pointer;"></i> -->
                          <a class="btn btn-success btn-circle btn-sm mr-3"
                          (click)="seleccionarOficina(customer)"
                          title="Editar">
                          <i class="fas fa-pen"></i>
                          </a>
                          <a class="btn btn-danger btn-circle btn-sm ml-3" 
                        (click)="EliminarCliente(customer)"
                          title="Eliminar">
                          <i class="fas fa-times"></i>
                          </a>
                        </td>
                        <!-- <td (click)="EliminarCliente(customer)" *ngIf="sesionrol == 1">
                          <i class="fas fa-trash" style="cursor: pointer;"></i>
                        </td> -->
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="10">No hay clientes para mostrar.</td>
                      </tr>
                    </ng-template>
                  </p-table>

                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- /.Left col -->
        <!-- right col (We are only adding the ID to make the widgets sortable)-->
        <!-- right col -->
      </div>
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>