import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RutService } from 'src/app/core/services/http/rut.service';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { SweetAlertService } from '../../../../core/services/sweet-alert/sweet-alert.service'
import { PruebasService } from '../../../../core/services/http/pruebas.service';


@Component({
  selector: 'app-pass-usu-modal',
  templateUrl: './pass-usu-modal.component.html',
  styleUrls: ['./pass-usu-modal.component.scss']
})
export class PassUsuModalComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean = false;
  rutValid: boolean = false;
  // Asignar respuesta para el front
  roles: any[] = []
  oficinas: any[] = []
  ofi;
  title;
  joaquin
  onClose: Subject<boolean> = new Subject();
  Estado = [
    {
      estado: 'Habilitado',
      status: true
    },
    {
      estado: 'Desahabilitado',
      status: false
    }
  ]
  restUsuario: any = {
    dniUsu: '',
    nomUsu: '',
    sPassusu: '',
    bHab: '',
    idRol: '',
    idOficina: '',
    nombreRol: '',
    nombreOficina: '',
  }
  datosInterface;
  //@Input() usuOfi;
  @Input() public usuario: any;
  constructor(private modalService: ModalService,
    private formBuilder: FormBuilder,
    private PruebasService: PruebasService,
    private rutService: RutService,
    private SweetAlertService: SweetAlertService,
    private router: Router) {
      this.joaquin = localStorage.getItem('rut');
      this.iniciarDato(this.joaquin);
  }

  async ngOnInit(): Promise<void> {

    this.SweetAlertService.swalLoading();
    await this.obtenerRol();
    await this.obtenerOficina();
    // await this.setValue('15473497-k');

    this.SweetAlertService.swalClose();

  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    // this.onClose.next(true);
    this.modalService.closeModal();
  }
  // Inicia el formulario 
  async iniciarDato(rut:string) {
    this.registerForm = this.formBuilder.group({
      dniUsu: [rut, [Validators.required, Validators.minLength(8)]],
      nomUsu: [''],
      sPassusu: this.usuario === undefined ? [] : ['', [Validators.required, Validators.minLength(4)]],
      bHab: [''],
      idRol: [''],
      idOficina: ['']
    })
  }

  refresh(): void {
    window.location.reload();
  }
  async obtenerOficina() {
    this.oficinas = await this.PruebasService.traerOficinas()
  }

  async obtenerRol() {
    this.roles = await this.PruebasService.TraerRoles()
  }

  setRut(): void {
    this.rutValid = this.rutService.validarRut(
      this.registerForm.value.dniUsu.replace(/\./g, '').replace('-', '')
    );
    // this.registerForm.patchValue({
    //   dniUsu: this.rutService.formateaRut(this.registerForm.value.dniUsu),
    // });

    if (this.rutValid) {
      // Validando que el rut ingresado contenga sólo números y la letra K
      const rutRegex = /^\d{1,3}(?:\.\d{1,3}){2}-[\dkK]$/;
      this.rutValid = rutRegex.test(this.registerForm.value.dniUsu);
    }
  }

  async editarUsuario() {
    const dato = this.registerForm.value.dniUsu
    let usuario = await this.PruebasService.obtenerUsuarios();
    const dataUsuario = usuario.data

    dataUsuario.forEach(element => {
      if (element.sDniUsu === dato) {

        this.restUsuario = {
          dniUsu: this.registerForm.value.dniUsu,
          nomUsu: element.sNombreUsu,
          sPassusu: this.registerForm.value.sPassusu,
          bHab: element.bHab,
          idRol: element.idRol,
          idOficina: element.idOficina
        }

      }else{
        // this.SweetAlertService.swalError("¡No se puede validar el rut, intente nuevamente!");
        this.cerrarModal();
      }

    });
    if (this.restUsuario.dniUsu.trim().length === 0 || null) {
      this.cerrarModal();
      return;
    }
    let datosRetornados = await this.PruebasService.actualizarUsuarios(this.restUsuario)
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito", "¡Usuario actualizado!");
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se puede actualizar el usuario, intente nuevamente!");
    }
    setTimeout(() => {
      this.refresh();
    }, 2000);
  }

  onSubmit(cerrar) {
    if (cerrar == true) {
      this.cerrarModal();
    }
    else {
      //console.log('Enviando');
      this.editarUsuario();
      this.onReset();
      return;

    }


  }

  onReset() {
    setTimeout(() => {
      this.registerForm.reset();
    }, 1000);
  }
  // Metodos de validacion en html
  formDirtyRequire(dato: any) {
    return this.registerForm.get(dato).errors?.dirty && this.registerForm.get(dato).errors?.required
  }
  formDirty(dato: any) {
    return this.registerForm.get(dato).errors?.dirty
  }
  formTouchLenght(dato: any) {
    return this.registerForm.get(dato).touched && this.registerForm.get(dato).errors?.minLength
  }
  formTouched(dato: any) {
    return this.registerForm.get(dato)?.touched
  }
  formErrors(dato: any) {
    return this.registerForm.get(dato).errors
  }
  campoRequerido(dato: any) {
    return this.registerForm.get(dato)?.errors?.required
  }
  minLargo(dato: any) {
    return this.registerForm.get(dato)?.errors?.minlength
  }
  minLargoRequerido(dato: any) {
    return this.registerForm.get(dato)?.errors?.minlength.requiredLength
  }
  maxLargo(dato: any) {
    return this.registerForm.get(dato)?.errors?.maxlength
  }
  maxLargoRequerido(dato: any) {
    return this.registerForm.get(dato)?.errors?.maxlength.requiredLength
  }
  caracterEspecial(dato: any) {
    return this.registerForm.get(dato)?.errors?.pattern
  }
  validaRut(): void {
    this.rutValid = this.rutService.validarRut(this.registerForm.value.rut.replace(/\./g, '').replace('-', ''));
    this.registerForm.patchValue({ rut: this.rutService.formateaRut(this.registerForm.value.registerForm) })
  }

  GetRolSelect(rol) {
    for (let index = 0; index < this.roles["data"].length; index++) {
      const element = this.roles["data"][index];
      if (element["descripcion"] == rol) {
        //console.log("encontrol rol", element);

        return element["idRol"]
      }
    }
  }

  GetOficinaSelect(ofi) {
    for (let index = 0; index < this.oficinas["dataOfi"].length; index++) {
      const element = this.oficinas["dataOfi"][index];
      if (element["sOficina"] == ofi) {
        //console.log("encontrol oficina", element);

        return element["idOficina"]
      }
    }
  }

}
