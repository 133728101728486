<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 390px !important; margin-top: 10%;">
    <h5 class="card-header mt-4" class="text-secondary">Crear Elemento</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary" *ngIf="registerForm">
            <div class="form-group">
                <label for="exampleInputZone">Tipo de Elemento</label>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('idTipo').errors}" formControlName="idTipo" >
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option *ngFor="let Tipo of TipoElementos" [ngValue]="Tipo.idTiposElemento">{{ Tipo.descripcion }}</option>
                </select>
                <div *ngIf="submitted && registerForm.get('idTipo').errors">
                    <small class="msg-error">Seleccione un tipo de elemento</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Serial del Elemento</label>
                <input type="text" [ngClass]="{'is-invalid': submitted && registerForm.get('serial').errors}" formControlName="serial" class="form-control" >
                <div *ngIf="submitted && registerForm.get('serial').errors">
                <small class="msg-error">Ingrese un serial</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Estado</label><br>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('estado').errors}" 
                formControlName="estado" placeholder="Selecciona">
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option value="habilitado">Habilitado</option>
                    <option value="deshabilitado">Deshabilitado</option>
                </select>
                <div *ngIf="submitted && registerForm.get('estado').errors">
                    <small class="msg-error">Seleccione un estado</small>
                </div>
            </div> 
        </form>
    </div>
    <form *ngIf="registerForm">
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarCliente()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>