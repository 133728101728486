<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-auto">
          <h1 class="m-0 pl-4 subtitle">Resumen Locker</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6 mt-1 border-left border-right">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a href="#" [routerLink]="['/MenuOpciones']">Santander</a></li>
            <li *ngIf="rolsesion != 3" class="breadcrumb-item active">Resumen Locker</li>
            <li *ngIf="rolsesion == 3" class="breadcrumb-item active">Busqueda de Cliente</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content" *ngIf="rolsesion != 3">
    <div class="container-fluid d-flex">
      <!-- Small boxes (Stat box) -->
      <div class="row d-flex col-4 h-50">
        <div class="col-lg-12 col-sm-12 col-12">
          <!-- small box -->
          <div class="small-box bg-info">
            <div class="inner">
              <h6>Total casilleros</h6>
              <h3 class="num-popp">{{total}}</h3>

              <p>.</p>
            </div>

            <div class="icon">
              <div class="ico-box"> <img src="/assets/img/herramientas.svg">
                <!-- <i class="ion ion-bag"></i> -->
              </div>
            </div>

          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-12 col-sm-12 col-12">
          <!-- small box -->
          <div class="small-box bg-info ">
            <div class="inner">
              <h6>Casilleros Disponibles</h6>
              <h3 class="num-popp">{{Disponibles}}</h3>
              <p>El día de hoy</p>
            </div>
            <div class="icon">
              <div class="ico-box"> <img src="/assets/img/herramientas.svg">

              </div>
            </div>


          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-12 col-sm-12 col-12">
          <!-- small box -->
          <div class="small-box bg-info ">
            <div class="inner">
              <h6>Casilleros Usados</h6>
              <h3 class="num-popp">{{Usados}}</h3>
            </div>

            <div class="icon">
              <div class="ico-box"> <img src="/assets/img/herramientasDanadas.svg">

              </div>
            </div>


          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-12 col-sm-12 col-12">
          <!-- small box -->
          <div class="small-box bg-info ">
            <div class="inner">
              <h6>Pedidos Vencidos</h6>
              <h3 class="num-popp">{{Expirados}}</h3>
              <p>En general</p>
              <button type="button" class="btn btn-success" (click)="exportExcel()">Export Excel</button>
            </div>
            <div class="icon">
              <div class="ico-box" (click)="exportExcel()" style="cursor: pointer;">
                <img src="/assets/img/herramientasDanadas.svg">
              </div>
            </div>
          </div>
        </div>
        <!-- ./col -->
      </div>
      <!-- /.row -->
      <!-- Main row -->
      <div class="row col-sm-12 col-8 col-lg-8">
        <!-- Left col -->
        <section class="col-lg-12  connectedSortable">
          <!-- Custom tabs (Charts with tabs)-->
          <div class="card box-success box">
            <!-- /.card-header -->
            <div class="card-body">
              <app-locker></app-locker>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
          <!-- /.card -->
        </section>
        <!-- /.Left col -->
        <!-- right col (We are only adding the ID to make the widgets sortable)-->
        <!-- right col -->
      </div>
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->

  <!-- Busqueda cliente solo sesion 3 -->
  <section *ngIf="rolsesion == 3">
    <form [formGroup]="registerForm" class="text-secondary" *ngIf="registerForm" (ngSubmit)="onSubmit()">
      <div class="container">
        <div class="form-group">
          <label for="dniUsu">Rut CLiente </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="dniUsu"><i class="fas fa-id-card"></i></span>
            </div>
            <input type="text" class="form-control" name="dniUsu" formControlName="dniUsu"
              [ngClass]="{ 'is-invalid': formDirtyRequire('dniUsu')}" (keyup)="setRut()">
          </div>
          <div *ngIf="!rutValid && registerForm.value.dniUsu.length > 7">
            <div *ngIf="registerForm.value.dniUsu.length > 12">
              <small class="text-danger">
                Ingrese un Rut valido con un maximo de 99.999.999-9
              </small>
            </div>
          </div>
          <div *ngIf="!rutValid && registerForm.value.dniUsu.length > 7">
            <div *ngIf="registerForm.value.dniUsu.length < 8">
              <small class="text-danger">
                Por favor ingresa un rut válido con un minimo de 8 digitos
              </small>
            </div>
          </div>

          <div *ngIf="!rutValid && registerForm.value.dniUsu.length > 7">
            <small class="text-danger">
              Formato incorrecto por favor ingresa un rut válido
            </small>
          </div>

          <div class="text-center mt-5">
            <button class="btn btn-primary mr-1" type="submit" [disabled]="!rutValid">
              Buscar
            </button>
          </div>

        </div>
      </div>
    </form>
    <!-- Reporte logs -->
    <div>
      <div class="container-fluid main">
        <div class="fade-in">
          <div class="card">
            <div class="card-header">
  
              <div class="row">
                <div class="col-sm-12 text-center">
  
                  <h4 class="text-dark">Reporte LOG cliente</h4>
                  <div class="row">
                    <!-- <button (click)="exportExcel()" *ngIf="dataDia.length > 0">Exportar a Excel</button>
                    <button (click)="exportPdf()" *ngIf="dataDia.length > 0">Exportar a PDF</button> -->
                  </div>
                </div>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 text-center display-4" *ngFor="let log of logs">
                  <!-- <h4> {{log.sOficina}} </h4> -->
                </div>
                
                <div id="excel-table" class="col-sm-12 table-responsive" >
                  <table class="table table-striped">
                    <thead>
                      <tr >
                        <th scope="col">Oficina</th>
                        <th scope="col">Nom Oficina</th>
                        <th scope="col">Rut</th>
                        <th scope="col">Casillero </th>
                        <th scope="col">Nom locker</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Hora</th>
                        <th scope="col">descripción</th>
                        <th scope="col">Operación</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let item of logs">
                        <th scope="row">{{item.idOficina}}</th>
                        <td>{{item.sOficina}}</td>
                        <td>{{item.rutDestinatario}}</td>
                        <td>{{item.casillero}}</td>
                        <td>{{item.sNomLocker}}</td>
                        <td>{{item.fecha | date}}</td>
                        <td>{{item.hora}}</td>
                        <td>{{item.descripcion}}</td>
                        <td>{{item.tipoOperacion}}</td>
                        <td>{{item.estadoOperacion}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 

</div>
<!-- /.content-wrapper -->