import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProcessService } from '../../../core/services/http/data-process.service';
import { PruebasService } from '../../../core/services/http/pruebas.service';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../core/services/modals/modal.service'
import { AdmiUsuModalComponent } from '../../admi-usu-ofi/admi-usu-modal/admi-usu-modal.component';
import { PassUsuModalComponent } from './pass-usu-modal/pass-usu-modal.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() step: string;
  ofiselect;
  usuario;
  rolsesion;
  urgencia = false;
  ayuda = false;
  sitio = "";
  data: any;
  localData: any;
  oficinas: any[];
  modalRef        : BsModalRef;
  constructor(
    private router: Router,
    private dataProcessService: DataProcessService,
    private pService: PruebasService,
    private modalService      : ModalService,
  ) { }

  ngOnInit(): void {
    this.rolsesion = localStorage.getItem("rolsesion");
    this.data = this.dataProcessService.getDataProcess();
    this.localData = localStorage.getItem('rut');
    this.oficinas;
    this.buscar();
    this.usuario = JSON.parse(localStorage.getItem("usulogeado"));
    // //console.log("RUT: ", this.data["rut"]);
    // //console.log("RUT LOCAL STORAGE: ", this.localData);
  }

  async buscar() {
    // //console.log(this.data);
   var resp = await  this.pService.TraerOficinas();
      if (resp["status"] === false) {
        return;
      }
      this.oficinas = resp["data"];
      this.oficinas.forEach(element => {
        if (element["idOficina"] == this.usuario["idOficina"]) {
          this.ofiselect = element;
        }
      });
  }

  cambio (){
    const opcion = { class: 'w-50', initialState: {title:'Cambio contraseña'} }
    this.modalRef = this.modalService.openModal(PassUsuModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      //console.log('result: ', result);
      // if (result) {
      //   this.llenarTabla()
      // }
    });
  }
  setoficina(ofi) {
    //console.log(ofi);
    //console.log(this.ofiselect);

    localStorage.setItem('ofiselect', ofi["idOficina"])


  }

  RenviarCorreo() {
    this.router.navigate(['reenviar-correo']);
  }

  redireccionReporteriaCierre() {
    this.router.navigate(['cierre']);
  }
  redireccionReporteriaLog() {
    this.router.navigate(['reporte-log']);
  }

  redireccionParaPedido() {
    this.router.navigate(['NuevoPedido']);
  }
  Dashboard() {
    this.router.navigate(['MenuOpciones']);
  }
  redireccionParaRetiro() {
    this.router.navigate(['RetirarPedido'], { queryParams: { 'idcasillero': '5', 'idLocker': '5', 'idOficina': '5' } });
  }
  redireccionTablaPedidos() {
    this.router.navigate(['TablaPedido']);
  }
  redireccionTablaZona() {
    this.router.navigate(['TablaZona']);
  }
  redireccionTablaRegiones() {
    this.router.navigate(['TablaRegiones']);
  }
  redireccionTablaComunas() {
    this.router.navigate(['TablaComunas']);
  }
  redireccionTablaOficinas() {
    this.router.navigate(['TablaOficinas']);
  }
  redireccionTablaBodegas() {
    this.router.navigate(['TablaBodegas']);
  }
  redireccionTablaClientes() {
    this.router.navigate(['TablaClientes']);
  }
  redireccionTablaUsuarios() {
    this.router.navigate(['TablaUser']);
  }
  redireccionTablaLockers() {
    this.router.navigate(['TablaLockers']);
  }
  redireccionTablaCasilleros() {
    this.router.navigate(['TablaCasilleros']);
  }
  redireccionTablaUsuariosOfi() {
    this.router.navigate(['TablaUsuariosOfi']);
  }
  redireccionTipoElemento() {
    this.router.navigate(['TipoElemento']);
  }
  redireccionElemento() {
    this.router.navigate(['Elemento']);
  }
  redireccionHome() {
    this.router.navigate(['']);
  }

  //MethaNext
  redireccionMasivaElementos() {
    this.router.navigate(['CargaMasivaElementos']);
  }
  redireccionMasivaUsuarios() {
    this.router.navigate(['CargaMasivaUsuarios']);
  }



}
