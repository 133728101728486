<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Detalle</h5>
    <button type="button" class="close" (click)="closeModal()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="detalleLog">
    <div class="row">
      <div class="col-12 mb-5 text-right">
        <button type="button" class="btn btn-primary" (click)="export()">Exportar</button>
      </div>
      <div class="col-12 table-responsive">
        <div class="mb-5" style="display: table; width: 100%;">
          <div style="display: table-row-group;">
            <div style="display: table-row; background-color: #0000150d;">
              <div class="divTableCell font-weight-bold">Id sesión</div>
              <div class="divTableCell font-weight-bold">Fecha</div>
              <div class="divTableCell font-weight-bold">Hora</div>
              <!-- <div class="divTableCell font-weight-bold">Id Carro</div> -->
              <div class="divTableCell font-weight-bold">Descripción</div>
              <div class="divTableCell font-weight-bold">Tipo Operación</div>
              <div class="divTableCell font-weight-bold">Estado Operación</div>
              <!-- <div class="divTableCell font-weight-bold">Rut Destinatario</div> -->
              <div class="divTableCell font-weight-bold">Rut Carrier</div>
              <!-- <div class="divTableCell font-weight-bold">Id Oficina</div> -->
              <!-- <div class="divTableCell font-weight-bold">Número OT</div> -->
              <div class="divTableCell font-weight-bold">Casillero</div>
              <!-- <div class="divTableCell font-weight-bold">Clave</div> -->
              <!-- <div class="divTableCell font-weight-bold">Puntuación</div> -->
              <div class="divTableCell font-weight-bold">Request</div>
              <div class="divTableCell font-weight-bold">Response</div>
            </div>
            
            <div style="display: table-row">
              <div class="divTableCell col-sm-1">{{ detalleLog.idSesion }}</div>
              <div class="divTableCell col-sm-2">{{ detalleLog.fecha }}</div>
              <div class="divTableCell col-sm-1">{{ detalleLog.hora }}</div>
              <!-- <div class="divTableCell col-sm-1">{{ detalleLog.idCarro }}</div> -->
              <div class="divTableCell col-sm-1">{{ detalleLog.descripcion }}</div>
              <div class="divTableCell col-sm-1">{{ detalleLog.tipoOperacion }}</div>
              <div class="divTableCell col-sm-1">{{ detalleLog.estadoOperacion }}</div>
              <!-- <div class="divTableCell col-sm-1">{{ detalleLog.rutDestinatario }}</div> -->
              <div class="divTableCell col-sm-1">{{ detalleLog.rutCarrier }}</div>
              <!-- <div class="divTableCell col-sm-1">{{ detalleLog.idOficina }}</div> -->
              <!-- <div class="divTableCell col-sm-1">{{ detalleLog.numeroOt }}</div> -->
              <div class="divTableCell col-sm-1">{{ detalleLog.casillero }}</div>
              <!-- <div class="divTableCell col-sm-1">{{ detalleLog.clave }}</div> -->
              <!-- <div class="divTableCell col-sm-1">{{ detalleLog.puntuacion }}</div> -->
              <div class="divTableCell col-sm-3" style="white-space: pre">{{ detalleLog.request ? (detalleLog.request | json) : '-'}}</div>
              <div class="divTableCell col-sm" style="white-space: pre">{{ detalleLog.request ? (detalleLog.response | json) : '-'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>