import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { EmitDatatableService } from 'src/app/core/services/emit/emit-datatable.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @Output() changeAction = new EventEmitter<any>();
  @Output() tableData = new EventEmitter<any>();
  @Input() data: any[] = [];
  @Input() columnas: any[] = [];
  @Input() nameTabla: string;
  @Input() size: string;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  private subscription = new Subscription();

  constructor(
    private emitDatatableService: EmitDatatableService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.setTable();

    this.subscription.add(
      this.emitDatatableService.newData.subscribe(async (data: any) => {
        this.dtOptions.data = data;
        await this.reloadTable();
      })
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subscription.unsubscribe();
  }

  setTable(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 25, 50, 100],
      processing: true,
      responsive: true,
      language: {
        info: 'Mostrando _START_ al _END_ de _TOTAL_ registros',
        lengthMenu: 'Mostrar _MENU_ registros',
        infoFiltered: '(filtrado de un total de _MAX_ registros)',
        paginate: {
          first: '<i class="fa fa-angle-double-left"></i>',
          last: '<i class="fas fa-angle-double-right"></i>',
          next: '<i class="fas fa-angle-right"></i>',
          previous: '<i class="fas fa-angle-left"></i>'
        }
      },
      dom: '<"row"' +
              '<"col-sm-6"B>' +
              '<"col-sm-6"f>' +
            '>' +
            '<"row"' +
              '<"col-sm-12"tr>' +
            '>' +
            '<"row"' +
              '<"col-sm-4"i>' +
              '<"col-sm-4"l>' +
              '<"col-sm-4"p>' +
            '>',
      buttons: [
        {
          extend: 'excel',
          title: this.nameTabla,
          text: '<i class="far fa-file-excel"></i> Exportar a Excel',
          className: 'btn btn-success',
        },
        {
          extend: 'print',
          title: this.nameTabla,
          text: '<i class="far fa-file-pdf"></i> Exportar a PDF',
          className: 'btn btn-warning',
        }
      ],
      data: this.data,
      columns: this.columnas,
      order: []
    };

    /* Acción de botones */
    this.renderer.listen('document', 'click', (e) => {
      if (e.target.closest('button')) {
        const button = e.target.closest('button');

        if (button.className.match('btn-warning')) {
          this.changeAction.emit({ action: 'update', value: button.value });
        }

        if (button.className.match('btn-danger')) {
          this.changeAction.emit({ action: 'delete', value: button.value });
        }

        if (button.className.match('btn-export-log')) {
          this.changeAction.emit({ action: 'log', value: button.value });
        }

        if (button.className.match('btn-kioskos')) {
          this.changeAction.emit({ action: 'kioskos', value: button.value });
        }
      }
    });
  }

  rerender(): any {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next('');
    });
  }

  reloadTable(): void {
    this.rerender();
  }
}
