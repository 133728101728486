import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { AdminComponent } from './modules/admin/pages/admin.component';
import { LoginUsuarioComponent} from './modules/login-usuario/login-usuario.component';
import { AdmiUsuOfiComponent } from './modules/admi-usu-ofi/admi-usu-ofi.component';

import { AdmiClientesComponent } from './modules/admi-clientes/admi-clientes.component';
import { AdmiLockersComponent } from './modules/admi-lockers/admi-lockers.component';
import { AdmiOficinasComponent } from './modules/admi-oficinas/admi-oficinas.component';
import {MenuOpcionesComponent} from '../../src/app/modules/menu-opciones/menu-opciones.component';
import { combineAll } from 'rxjs/operators';
import { from } from 'rxjs';
import { ReporteCierreComponent } from '../app/modules/Reportería/reporte-cierre/reporte-cierre.component';
import { ReporteLogComponent } from '../app/modules/Reportería/reporte-log/reporte-log.component';
import {  TipoElementoComponent } from '../app/modules/elementos/tipo-elemento/tipo-elemento.component';
import {  ElementoComponent } from '../app/modules/elementos/elemento/elemento.component';
import { MasivaElementosComponent } from '../app/modules/carga-masiva/masiva-elementos/masiva-elementos.component';
import { MasivaUsuariosComponent } from '../app/modules/carga-masiva/masiva-usuarios/masiva-usuarios.component';


// import { LockerComponent } from './modules/shared/locker/locker/locker.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'reporte-log', component: ReporteLogComponent },
  { path: 'cierre', component: ReporteCierreComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'LoginUser', component: LoginUsuarioComponent },
  { path: 'TablaClientes', component: AdmiClientesComponent},
  { path: 'TablaUsuariosOfi', component: AdmiUsuOfiComponent},
  { path: 'TablaLockers', component: AdmiLockersComponent},
  { path: 'TablaOficinas', component: AdmiOficinasComponent},
  { path: 'MenuOpciones', component: MenuOpcionesComponent},

  //Methanet
  // { path: 'TipoElemento', component: TipoElementoComponent},
  // { path: 'Elemento', component: ElementoComponent},
  { path: 'CargaMasivaElementos', component: MasivaElementosComponent},
  { path: 'CargaMasivaUsuarios', component: MasivaUsuariosComponent}
  
  // { path: 'Locker', component: LockerComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
